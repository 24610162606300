<template>
    <iframe id="result"></iframe>
</template>

<script>
export default {
    name: "PreviewEmailTemplates",
    data() {
        return {
        }
    },
    mounted() {
        this.RenderPreview(decodeURI(this.$route.query.code));
    },
    methods: {
        RenderPreview: function (code) {
            var previewFrame = document.getElementById('result');
            var preview =  previewFrame.contentDocument ||  previewFrame.contentWindow.document;
            preview.open();
            preview.write(code);
            preview.close();
        }
    }
};
</script>

<style lang="scss" scoped>
    #result {
        width: 100%;
        border: none;
    }
</style>
